<template>
<img :src="imageUrl" style="height: auto; width: auto; max-width: 600px; max-height: 600px;" alt="File not found" class="mb-4 w-full user-latest-image" />
</template>

<script>
export default {
    name:"vx-image",
    data() {
        return {
            imageUrl: ''
        }
    },
    props: ['src'],
    watch: {
        src: {
            immediate: true,
            deep: true,
            handler(newValue, oldValue) {
                this.imageUrl = newValue;
            }
        }
    }
}
</script>
