<template>
<p>
    <span><a :class="`cursor-pointer ${textColor}`" @click="handleOpenTerms(termURL)">Terms and Conditions | </a></span>
    <span><a :class="`cursor-pointer ${textColor}`" @click="handleOpenTerms(policyURL)">Privacy Policy</a></span>
    <ViewFile :isOpenTerms="isOpenTerms" @closeWindow="closeWindow" :URL="fileURL" />
</p>
</template>

<script>
export default {
    name: "vs-terms",
    data() {
        return {
            isOpenTerms: false,
            termURL: "https://bhpstorage.blob.core.windows.net/cdn/Terms.pdf",
            policyURL: "https://bhpstorage.blob.core.windows.net/cdn/Privacy.pdf",
            fileURL: "",
        }
    },
    props: {
        textColor: {
            type: String,
            required: false,
            default: () => "text-white"
        },
    },
    methods: {
        closeWindow() {
            this.isOpenTerms = false;
        },
        handleOpenTerms(url) {
            if (!this.isOpenTerms) {
                this.isOpenTerms = true;
            }
            this.fileURL = url;
        }
    }
}
</script>
